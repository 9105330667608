import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyGrid = lazy(
  () => import("./grid-SpfnlnHp.js").then((module) => ({
    default: module.Grid
  }))
);
function Grid({ id: _id, name: _name, ...props }) {
  return /* @__PURE__ */ jsx(LazyGrid, { ...props });
}
const useGridContent = () => {
  return useMemo(
    () => ({
      grid: {
        component: Grid
      }
    }),
    []
  );
};
export {
  LazyGrid as Grid,
  useGridContent
};
